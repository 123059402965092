<template>
  <v-row justify="start">
    <v-dialog v-model="editFeeds" scrollable persistent max-width="500px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Update post</span>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <div class="px-2 pt-2 d-flex align-center">
          <h4></h4>
        </div>
        <v-card-text class="px-2" v-if="item">
          <v-container>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-textarea
                  name="ok"
                  hide-details
                  autofocus
                  v-model="item.data.feeds.content"
                  label="Write something!"
                  auto-grow
                  flat
                  solo
                ></v-textarea>
                <v-card v-if="picture" elevation="1" style="width: max-content">
                  <div class="d-flex flex-column">
                    <section class="d-flex justify-end temp">
                      <v-btn fab x-small color="red" depressed class="over" dark
                        ><v-icon>mdi-close</v-icon></v-btn
                      >
                    </section>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <div v-if="loading">
            <Waiting />
          </div>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined rounded @click="updateFeed">
            <v-icon class="mr-2">mdi-tooltip-check</v-icon>
            Post</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import Waiting from "../../_base/Modal/waiting";

export default {
  name: "editFeed",
  props: ["editFeeds", "item"],
  computed: {
    ...mapState({
      feedDetail: state => state.feeds.feedsDetail,
      env: state => state.API_URL,
      id: state => state.id
    })
  },
  components: {
    Waiting
  },
  data() {
    return {
      loading: false
    };
  },
  mounted() {
    //
  },
  methods: {
    updateFeed() {
      this.loading = true;
      let data = new FormData();
      data.append("content", this.item.data.feeds.content);
      this.$store
        .dispatch("feeds/updateFeeds", this.item.data.feeds)
        .then(data => {
          this.loading = false;
          this.$emit("close");
          this.$emit("refetch");
          Swal.fire({
            icon: "success",
            title: data.data.message,
            showConfirmButton: false,
            position: "top",
            timer: 3000,
            toast: true
          });
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
