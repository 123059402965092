<template>
  <div class="d-flex _bg-default _80w _100vh">
    <div class="_100top pa-3 _full-w" v-if="!loading">
      <div v-if="feedDetail.data">
        <v-card class="radius-card width__thread">
          <section>
            <div class="d-flex pa-3">
              <v-avatar class="mr-3">
                <img
                  :src="
                    feedDetail.data.dataUser.photo_profile
                      ? `${env}/upload/photo_profile/${feedDetail.data.dataUser.id}/${feedDetail.data.dataUser.photo_profile}`
                      : `${env}/images/logo.png`
                  "
                  class="img-fit"
                  alt=""
                />
              </v-avatar>
              <div>
                <h4>{{ feedDetail.data.dataUser.nama_lengkap }}</h4>
                <small class="grey--text">{{
                  $date(feedDetail.data.feeds.created_at).fromNow()
                }}</small>
              </div>
            </div>
          </section>
          <section class="px-5 py-3">
            {{ feedDetail.data.feeds.content }}
          </section>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="blue"
              @click="toComment"
              content="Comment"
              v-tippy="{
                arrow: true,
                arrowType: 'round',
                animation: 'fade',
                theme: 'light',
                maxWidth: 100
              }"
            >
              <v-icon>mdi-comment</v-icon>
            </v-btn>

            <div class="px-3">
              <v-btn
                text
                color="blue"
                @click="like(feedDetail.data.feeds.id)"
                content="Like"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 100
                }"
                v-if="!feedDetail.data.isLike"
              >
                <v-icon>mdi-heart</v-icon>
                <span> </span>
              </v-btn>
              <v-btn
                text
                color="red"
                @click="unlike(feedDetail.data.feeds.id)"
                v-if="feedDetail.data.isLike"
              >
                <v-icon>mdi-heart</v-icon>
                <span>
                  {{ feedDetail.data.likers }}
                </span>
              </v-btn>
            </div>

            <v-btn
              text
              class="mr-1"
              v-if="feedDetail.data.feeds.user_id == id"
              @click="updateFeed(feedDetail)"
              color="green"
              content="Edit Feed"
              v-tippy="{
                arrow: true,
                arrowType: 'round',
                animation: 'fade',
                theme: 'light',
                maxWidth: 100
              }"
              ><v-icon>mdi-pencil</v-icon></v-btn
            >

            <v-btn
              class="mr-1"
              color="red"
              text
              v-if="feedDetail.data.feeds.user_id == id"
              @click="delFeed(feedDetail.data.feeds.id)"
              content="Delete Feed"
              v-tippy="{
                arrow: true,
                arrowType: 'round',
                animation: 'fade',
                theme: 'light',
                maxWidth: 100
              }"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card class="my-3 radius-card width__thread">
          <v-toolbar dense flat>
            <h3>Komentar</h3>
          </v-toolbar>
          <div
            class="d-flex algn-center justify-center flex-column"
            v-if="!feedDetail.data.dataComment.length"
          >
            <img src="../../assets/img/404.svg" height="100px" alt="404" />
            <h4 class="text-center">No Comment</h4>
          </div>
          <div class="pa-3" v-else>
            <v-card
              class="radius-card pa-3 mb-2"
              v-for="(comment, idx) in feedDetail.data.dataComment"
              :key="idx"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex">
                  <v-avatar class="mr-3"
                    ><img
                      :src="
                        comment.photo_profile
                          ? `${env}/upload/photo_profile/${comment.user_id}/${comment.photo_profile}`
                          : `${env}/images/logo.png`
                      "
                      class="img-fit"
                      alt=""
                  /></v-avatar>
                  <div class="d-flex align-center">
                    <h4>{{ comment.nama_lengkap }}</h4>
                    <span class="mx-1"><b>.</b></span>
                    <small class="grey--text">
                      {{ $date(comment.created_at).fromNow() }}
                    </small>
                  </div>
                </div>
                <div v-if="comment.user_id == id">
                  <v-btn icon small color="blue" @click="editComment(comment)"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  >
                  <v-btn icon color="blue" @click="delComment(comment.id)"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </div>
              </div>
              <div class="mt-2">
                <p>{{ comment.content }}</p>
              </div>
            </v-card>
          </div>
          <div class="d-flex flex-column pa-3">
            <v-textarea
              name="comment"
              id="fieldComment"
              hide-details
              v-model="content"
              label="Write something!"
              auto-grow
              solo
            ></v-textarea>
            <div>
              <v-spacer></v-spacer>
              <v-btn
                class="mt-3 blue"
                dark
                @click="comment(feedDetail.data.feeds.id)"
                ><v-icon class="mr-2">mdi-tooltip-check</v-icon>Comment</v-btn
              >
            </div>
            <div id="comment"></div>
          </div>
        </v-card>
        <div class="pa-3">
          <EditCommentFeed
            v-bind:dialogComment="dialogComment"
            v-bind:dataComment="dataComment"
            @close="closeDialog"
            @fetch="fetchFeedById"
          />
          <EditFeed
            v-bind:editFeeds="editFeeds"
            v-bind:item="item"
            @close="closeEditFeed"
            @refetch="fetchFeedById"
          />
        </div>
      </div>
    </div>
    <div class="_100top pa-3 _full-w" v-if="loading">
      <v-skeleton-loader
        type="card-avatar, article, actions"
      ></v-skeleton-loader>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import EditCommentFeed from "../../components/Psycholog/Modal/editCommentFeed.vue";
import EditFeed from "../../components/Psycholog/Modal/editFeed.vue";

export default {
  components: { EditCommentFeed, EditFeed },
  name: "viewFeed",
  computed: {
    ...mapState({
      feedDetail: state => state.feeds.feedsDetail,
      env: state => state.API_URL,
      id: state => state.id
    })
  },
  data() {
    return {
      loading: true,
      content: "",
      dataComment: null,
      dialogComment: false,
      item: null,
      editFeeds: false
    };
  },
  mounted() {
    this.fetchFeedById();
  },
  methods: {
    fetchFeedById() {
      let id = this.$route.params.id;
      this.$store.dispatch("feeds/feedById", id).then(() => {
        this.loading = false;
      });
    },
    like(id) {
      this.$store.dispatch("feeds/likeFeed", id).then(() => {
        this.fetchFeedById();
      });
    },
    unlike(id) {
      this.$store.dispatch("feeds/unlikeFeed", id).then(() => {
        this.fetchFeedById();
      });
    },
    toComment() {
      document.getElementById("fieldComment").focus();
      let el = document.getElementById("comment");
      el.scrollIntoView();
    },
    comment(id) {
      let data = {
        id: id,
        model: "Feed",
        content: this.content
      };
      this.$store.dispatch("feeds/comment", data).then(() => {
        this.content = "";
        this.fetchFeedById();
        this.toComment();
      });
    },

    editComment(data) {
      this.dataComment = data;
      this.dialogComment = true;
    },

    closeDialog() {
      this.dialogComment = false;
    },

    delComment(id) {
      Swal.fire({
        title: "Apakah anda yakin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("feeds/deleteCommentFeed", id).then(() => {
            this.fetchFeedById();
            Swal.fire({
              icon: "success",
              title: "Komentar terhapus!",
              showConfirmButton: false,
              position: "top-end",
              toast: true,
              timer: 3000
            });
          });
        }
      });
    },

    updateFeed(item) {
      this.item = item;
      this.editFeeds = true;
    },

    closeEditFeed() {
      this.editFeeds = false;
    },

    delFeed(id) {
      Swal.fire({
        title: "Apakah anda yakin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("feeds/deleteFeeds", id).then(() => {
            this.$router.push(`/psikolog/feed`);
            Swal.fire({
              icon: "success",
              title: "Feed telah terhapus!",
              showConfirmButton: false,
              position: "top-end",
              toast: true,
              timer: 3000
            });
          });
        }
      });
    }
  }
};
</script>

<style scoped>
div >>> img {
  /* height: 200px; */
  width: 100%;
}
.width__thread {
  position: relative;
  width: 95%;
  box-sizing: border-box !important;
}
@media (max-width: 576px) {
  .width__thread {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .width__thread {
    width: 100%;
  }
}
</style>
